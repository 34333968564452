
import LazyHydrate from 'vue-lazy-hydration'
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

interface PrimaryPhotoType {
  caption?: string
  url: {
    masterImage: string
  }
}

@Component({
  components: {
    LazyHydrate,
  },
})
export default class PrimaryPhotoAndGallery extends Vue {
  galleryLoaded = false

  @Prop({ required: true })
    primaryPhoto: PrimaryPhotoType

  @Prop({ required: true })
    photos: PrimaryPhotoType[]

  @Prop({ required: false })
    photoCount: number

  @Prop({ default: false })
    isSearchResults: boolean

  @Prop({ default: false })
    galleryFullScreen!: boolean

  @Prop({ required: false })
    searchResultIndex: number

  @Prop({ required: false })
    showSearchGallery: boolean

  @Prop()
    campsiteName: string

  @Prop({ required: false })
    galleryInitialIndex: number

  @Prop({ default: true })
    loadHighResOnInteraction: boolean

  get primaryPhotoSizes() {
    if (this.$isDesktop) {
      return this.campsiteName ? '45vw' : '25vw'
    } else {
      return '100vw'
    }
  }
}
