// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/pitchup.com-vector-logo.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".image-wrapper[data-v-e91ea928]{display:block;height:100%;overflow:hidden;position:relative;width:100%}.primary-photo[data-v-e91ea928]{left:0;position:absolute;width:100%}.inline-gallery-loading-message[data-v-e91ea928]{align-items:center;background:#bec1c6 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-position-y:35%;background-size:35% 35%;bottom:0;color:var(--colour-text-light);display:flex;font-size:var(--small);font-style:italic;height:100%;justify-content:center;left:auto;padding-top:22%;position:absolute;right:auto;text-align:center;top:0;width:100%}@media screen and (min-width:320px) and (max-width:998px) and (orientation:landscape){.image-wrapper[data-v-e91ea928]{aspect-ratio:4/3;height:auto;width:100%}}.image-wrapper.desktop[data-v-e91ea928]{aspect-ratio:4/3;border-radius:0;height:auto;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
