
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { photoCaptionAltFormatter } from '~/utility/photoCaptionAltFormatter'

interface PrimaryPhotoType {
  caption?: string
  url: {
    masterImage: string
  }
}

@Component
export default class PrimaryPhoto extends Vue {
  @Prop({ required: true })
    photo: PrimaryPhotoType

  @Prop({ default: '100vw' })
    sizes: string

  @Prop({ default: true })
    lazy: boolean

  photoCaptionAltFormatter(caption) {
    return photoCaptionAltFormatter(caption)
  }
}
