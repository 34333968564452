
import { PitchupPhoto } from '~/shared/photos/types'
import { photoCaptionAltFormatter } from '~/utility/photoCaptionAltFormatter'
import { Component, Prop, Vue, Watch } from '~/utility/pu-class-decorator'
import { capitalize } from '~/utility/text'

@Component
export default class SearchInlineGallery extends Vue {
  @Prop({ required: true })
    primaryPhoto: PitchupPhoto

  @Prop({ required: true })
    photos: PitchupPhoto[]

  @Prop({ required: false })
    photoCount: number

  @Prop()
    searchResultIndex: number

  @Prop({ default: true })
    loadHighResOnInteraction: boolean

  imgProps(photo) {
    const props = {
      srcset: `
        /_cfi/cdn-cgi/image/format=auto,fit=cover,quality=80,w=360,h=270${photo.url.masterImage} 360w,
        /_cfi/cdn-cgi/image/format=auto,fit=cover,quality=30,w=720,h=540${photo.url.masterImage} 720w,
        /_cfi/cdn-cgi/image/format=auto,fit=cover,quality=30,w=1080,h=810${photo.url.masterImage} 1080w
      `,
      src: `/_cfi/cdn-cgi/image/format=auto,fit=cover,quality=80,w=360,h=270${photo.url.masterImage}`,
    }

    return props
  }

  get galleryPhotos() {
    // ensure primary is first
    const photos: PitchupPhoto[] = []
    if (this.primaryPhoto) {
      photos.push(this.primaryPhoto)
    }
    (this.photos || [])
      .filter((photo) => photo?.url?.masterImage)
      .forEach((photo) => {
        if (
          this.isDifferentPhoto(
            photo.url.masterImage,
            photos[0].url.masterImage,
          )
        )
          photos.push(photo)
      })

    return photos
  }

  isDifferentPhoto(firstUrl: string, secondUrl: string) {
    const firstPhoto = firstUrl.split('/')
    const seconPhoto = secondUrl.split('/')
    return (
      firstPhoto[firstPhoto.length - 1] != seconPhoto[seconPhoto.length - 1]
    )
  }

  currentImage = 1
  isMounted = false
  galleryInUse = false
  firstImageLoaded = false

  mounted() {
    if (!this.loadHighResOnInteraction) {
      return
    }
    this.isMounted = true
    this.$nextTick(() => {
      const gallery = this.$refs.gallery as HTMLDivElement
      if (gallery) {
        const scrollPos = gallery?.dataset?.galleryPosition
        if (scrollPos) {
          gallery.scrollLeft = parseInt(scrollPos)
          this.debounce(this.calculateGalleryPosition)
        } else {
          gallery.scrollLeft = 0
          this.debounce(this.calculateGalleryPosition)
        }
      }
      gallery?.addEventListener('scroll', () => {
        this.$emit('user-has-interacted')
        this.galleryInUse = true
        this.debounce(this.calculateGalleryPosition)
      })
    })
    this.checkFirstImageLoaded()
  }

  @Watch('galleryPhotos', { immediate: true })
  onGalleryPhotosChanged() {
    this.checkFirstImageLoaded()
  }

  checkFirstImageLoaded() {
    if (process.server || this.firstImageLoaded) {
      return
    }
    if (this.galleryPhotos.length > 0) {
      const img = new Image()
      img.src = this.galleryPhotos[0].url.masterImage
      img.onload = () => {
        setTimeout(() => {
          this.firstImageLoaded = true
        }, 500)
      }
    }
  }

  calculateGalleryPosition() {
    const gallery = this.$refs.gallery as HTMLDivElement
    if (!gallery) return

    const galleryScrollLeft = gallery.scrollLeft
    const galleryImages = gallery.children

    if (galleryScrollLeft === 0) {
      this.currentImage = 1
    }

    if (galleryScrollLeft > 0 && galleryImages) {
      for (let i = 0; i < galleryImages.length; i++) {
        if (
          galleryScrollLeft + 50 >
            (galleryImages[i] as HTMLImageElement).offsetLeft &&
          galleryScrollLeft - 50 <
            (galleryImages[i] as HTMLImageElement).offsetLeft
        ) {
          this.currentImage = i + 1
          break
        }
      }
    }
    this.galleryInUse = false
  }

  debouncedFunction: ReturnType<typeof setTimeout>
  debounce(functionToDebounce) {
    clearTimeout(this.debouncedFunction)
    this.debouncedFunction = setTimeout(functionToDebounce, 500)
  }

  capitalize(value: string) {
    return capitalize(value)
  }

  photoCaptionAltFormatter(caption) {
    return photoCaptionAltFormatter(caption)
  }

  activated() {
    const gallery = this.$refs.gallery as HTMLDivElement
    if (!gallery) return

    if (this.currentImage !== 1) {
      gallery.scrollLeft = (
        gallery.children[this.currentImage - 1] as HTMLImageElement
      ).offsetLeft
    } else {
      gallery.scrollLeft = 0
    }
  }
}
