// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/pitchup.com-vector-logo.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/background-fade.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".search-inline-gallery-section[data-v-ad45fae4]{position:relative}.search-inline-gallery-loading-background[data-v-ad45fae4]{background:#bec1c6 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-position-y:35%;background-size:35% 35%}.search-inline-gallery[data-v-ad45fae4]{display:flex;overflow-x:auto;position:relative;scroll-snap-type:x mandatory;scrollbar-width:none}.search-inline-gallery-element[data-v-ad45fae4]{flex-shrink:0;scroll-snap-align:start;scroll-snap-stop:always;width:100%}.search-inline-gallery-loading-message[data-v-ad45fae4]{bottom:32%;color:var(--colour-text-light);font-size:var(--small);font-style:italic;left:auto;position:absolute;right:auto;text-align:center;width:100%}.show-loading .inline-gallery-loading-message[data-v-ad45fae4]{display:block}.photo-count-wrapper[data-v-ad45fae4]{align-items:center;display:flex;filter:drop-shadow(0 1px 1px rgba(0,0,0,.4));flex-direction:row;flex-shrink:0}.photo-count[data-v-ad45fae4]{color:#fff;font-size:var(--body)}.gallery-footer[data-v-ad45fae4]{align-items:center;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") repeat-x 0 100%;bottom:0;display:flex;flex-direction:row;justify-content:flex-start;left:0;padding:.3em 1.4em .3em 1.2em;pointer-events:none;position:absolute;right:0;transition:background .5s ease-in}.search-inline-gallery[data-v-ad45fae4]:not(.desktop)::-webkit-scrollbar{display:none}.gallery-footer[data-v-ad45fae4]{opacity:1;transition:opacity .3s ease-in}.gallery-scrollbar[data-v-ad45fae4]{background:#ffffff59;border-radius:.3em;height:.4em;margin:0 0 0 1em;pointer-events:none;position:relative;width:100%}.scrollbar-pip[data-v-ad45fae4]{background:#fff;border-radius:.3em;height:.4em;left:0;min-width:1em;position:absolute;top:0;z-index:1}.gallery-in-use .gallery-footer[data-v-ad45fae4]{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
